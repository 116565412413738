<template>
  <campaign-layout
    :showMenu="!computed_debug || !isSupervised"
    :showBack="!computed_debug"
    :pageTitle="$t('pageTitle.Ranking')"
    :noPadding="true"
  >
    <ion-row
      v-if="
        whappyClientConfig.name == 'Trivellato' ||
        whappyClientConfig.name == 'Demo' ||
        whappyClientConfig.name == 'Masterclass' ||
        (whappyClientConfig.name == 'Taccini' &&
          $store.state.user.userData.id_role == 112)
      "
      class="ion-justify-content-center"
    >
      <ion-col size="10">
        <!-- <ion-segment @ionChange="segmentChanged($event)" value="single"> -->
        <ion-segment v-model="listToShow">
          <ion-segment-button value="group">
            <ion-label>Di gruppo</ion-label>
          </ion-segment-button>
          <ion-segment-button value="single">
            <ion-label>Personale</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <div
          v-if="
            listToShow == 'single' ||
            (whappyClientConfig.name != 'Trivellato' &&
              whappyClientConfig.name != 'Demo' &&
              whappyClientConfig.name != 'Masterclass')
          "
        >
          <ion-searchbar
            v-model="filter"
            autocomplete="on"
            clear-icon
            search-icon
          ></ion-searchbar>
          <ion-list class="ion-padding-top">
            <transition-group name="block-rise-in">
              <template v-for="user of filteredItems" :key="user.id">
                <item-ranking
                  :user="user"
                  :perc_completamento="user.punti / maxPoints"
                  :style="{ '--i': user.position }"
                  :showPerc="user.percRanking"
                  v-if="
                    showList1 &&
                    showList2 &&
                    whappyClientConfig.name != 'Trivellato'
                  "
                  class="ion-no-padding overflow-visible"
                ></item-ranking>
                <item-ranking
                  :user="user"
                  :perc_completamento="user.punti / maxPoints"
                  :style="{ '--i': user.position }"
                  :showPerc="true"
                  v-else-if="
                    showList1 &&
                    showList2 &&
                    whappyClientConfig.name == 'Trivellato'
                  "
                  class="ion-no-padding overflow-visible"
                ></item-ranking>
              </template>
            </transition-group>
          </ion-list>
        </div>
        <div
          v-else-if="
            (listToShow == 'group' &&
              whappyClientConfig.name == 'Trivellato') ||
            whappyClientConfig.name == 'Demo' ||
            whappyClientConfig.name == 'Masterclass' ||
            (whappyClientConfig.name == 'Taccini' &&
              $store.state.user.userData.id_role == 112)
          "
        >
          <transition-group
            name="block-slide-in"
            :style="{ '--total': items.length }"
          >
            <template v-for="(item, i) in items">
              <div
                :key="i"
                :style="{ '--i': i }"
                v-if="showList1 && showList2"
                class="d-flex align-items-center"
                @click="toggleExpanded(item)"
              >
                <div>
                  <h4>{{ i + 1 }}</h4>
                </div>
                <ion-card class="flex-grow-1">
                  <ion-card-header>
                    <div
                      class="percentage"
                      style="color: white; text-align: end; font-weight: bold"
                    >
                      {{ item.Valore }}%
                    </div>
                    <div class="title">
                      <ion-card-title style="color: white" class="text-20">{{
                        item.Nome
                      }}</ion-card-title>
                    </div>
                    <div class="common-bar-container">
                      <common-progress-bar
                        :value="item.Valore / 100"
                      ></common-progress-bar>
                      <template
                        v-if="item.expanded && item.partecipanti.length > 0"
                      >
                        <div
                          class="ion-margin-bottom ion-margin-top ion-padding-bottom"
                          v-for="user in item.partecipanti"
                          :key="user.idUtente"
                        >
                          <div class="progress-container">
                            <div
                              class="light-title"
                              v-if="user.Nome && user.Cognome"
                            >
                              <div>{{ user.Nome }} {{ user.Cognome }}</div>
                              <div class="d-flex ion-justify-content-between">
                                <div class="user-ruolo">
                                  {{ user.Ruolo }}
                                </div>
                                <div>
                                  <span>{{ user.Valore.toFixed(0) }} %</span>
                                </div>
                              </div>
                            </div>
                            <common-progress-bar
                              :value="user.Valore / 100"
                              :avatar="user.ProfileImage"
                            >
                            </common-progress-bar>
                          </div>
                        </div>
                      </template>
                    </div>
                  </ion-card-header>
                </ion-card>
              </div>
            </template>
          </transition-group>
          <common-campaign-menu
            v-if="campaignMenuVisible"
            v-show="!showModal"
            on-award="true"
          ></common-campaign-menu>
        </div>
      </ion-col>
    </ion-row>
  </campaign-layout>
</template>

<script>
import CommonCampaignMenu from "@/components/CampaignMenu.vue";
import {
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonSearchbar,
  IonToolbar,
  IonList,
  IonItem,
  IonRow,
  IonCol,
  IonGrid,
  IonProgressBar,
  IonAvatar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";
import CommonProgressBar from "../../components/CommonProgressBar.vue";
// import { arrowForward } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { translate } from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import ItemRanking from "../../components/ItemRanking.vue";
import { useRoute } from "vue-router";
// import { ref } from "vue";
import CampaignService from "../../common/mixins/Campaign.vue";
export default {
  name: "Ranking",
  components: {
    IonCard,
    IonCardTitle,
    IonCardHeader,
    CommonProgressBar,
    ItemRanking,
    CampaignLayout,
    IonSearchbar,
    IonToolbar,
    IonList,
    IonItem,
    IonRow,
    IonCol,
    IonGrid,
    IonProgressBar,
    IonAvatar,
    IonSegment,
    IonSegmentButton,

    IonLabel,
    CommonCampaignMenu,
  },
  mixins: [CampaignService],
  // setup() {
  //   const route = useRoute();
  //   const { id } = route.params;

  //   return { id };
  // },
  created() {
    let route = useRoute();
    this.id = route.params.id;
    this.getGroupedRanking();
    this.getListUsers();

    this.isSupervised = this.isSupervisedCampaign;

    if (this.whappyClientConfig.name == "Yoube") {
      this.listToShow = "single";
    }
  },
  ionViewWillEnter() {
    this.showList1 = false;
    this.showList2 = false;

    setTimeout(() => {
      this.$nextTick(() => {
        this.showList1 = true;
      });
    }, 1);
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
  data() {
    return {
      isSupervised: false,
      id: null,
      showList1: false,
      showList2: false,
      avatarPosition: null,
      filter: "",
      users: [],
      listToShow: "group",
      items: [],
      campaignMenuVisible: false,
      // youbeItems: [],
    };
  },
  methods: {
    async getGroupedRanking() {
      let url = "";

      url = `CampaignManager/GetGroupedRankingByArea/${this.$route.params.id}`;

      await ApiService(url).then((res) => {
        this.items = res.data;
      });
    },
    toggleExpanded(item) {
      console.log(item.expanded);
      item.expanded = !item.expanded;
    },
    segmentChanged(event) {
      this.listToShow = event.detail.value;
    },
    async getListUsers() {
      await ApiService.get(`CampaignManager/GetRanking/${this.id}`).then(
        (res) => {
          res.data.forEach((element) => {
            element.name = element.nome + " " + element.cognome;

            this.users.push(element);
          });
        }
      );
      this.selectionSort(this.users).reverse();
    },
    selectionSort(arr) {
      let min;

      //start passes.
      for (let i = 0; i < arr.length; i++) {
        //index of the smallest element to be the ith element.
        min = i;

        //Check through the rest of the array for a lesser element
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[j].punti < arr[min].punti) {
            min = j;
          }
        }

        //compare the indexes
        min !== i ? ([arr[i], arr[min]] = [arr[min], arr[i]]) : null;
      }

      arr.reverse();

      let position = 1;

      arr.forEach((element) => {
        element.position = position;
        position++;
      });

      return arr.reverse();
    },
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return true;
      } else {
        return false;
      }
    },
    computed_listToShow() {
      if (
        this.whappyClientConfig.name == "Trivellato" ||
        this.listToShow == "group"
      ) {
        return "group";
      } else if (
        this.whappyClientConfig.name != "Trivellato" ||
        this.listToShow == "single"
      ) {
        return "single";
      } else {
        return "single";
      }
    },
    filteredItems() {
      if (this.filter) {
        return this.users.filter((user) => {
          return user.name.toLowerCase().includes(this.filter.toLowerCase());
        });
      } else {
        return this.users;
      }
    },
    maxPoints() {
      let punti = 0;
      this.users.forEach((element) => {
        if (element.punti >= punti) {
          punti = element.punti;
        }
      });
      return punti;
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: 1px solid greenyellow;
// }

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center {
  align-items: center;
}

.user-ruolo {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: lighter;
}

.progress-bar {
  padding: 5px 15px 10px;
}
</style>
